<template>
  <div>
    <v-row justify="center">
      <div v-if="dialog && dataConfig">
        <v-dialog
          :value="dialog"
          persistent
          max-width="50%"
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar>
              <v-btn icon @click="$emit('onCloseDialog')">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title class="text-uppercase">
                {{
                  !dataConfig._id ? $t("title.configSlotLimit") : $t("title.configSlotLimit")
                }}</v-toolbar-title
              >
              <v-spacer></v-spacer>
              <!-- <v-toolbar-items>
                <v-btn :disabled="loading" text @click="validate">
                  {{ !dataConfig._id ? $t("button.create") : $t("button.update") }}
                </v-btn>
              </v-toolbar-items> -->
            </v-toolbar>
            <v-card-text>
              <v-container>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-row>
                    <v-col cols="12" sm="12" md="12" style="padding:0px;">
                    <v-checkbox v-model="dataConfig.enable_lotto" label="ដាក់ដំណើរការ(Lotto)"></v-checkbox>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="dataConfig.total_bet_win_a_day_lotto"
                        :rules="rules.total_bet_win_a_day_lotto"
                        type="number"
                        :label="$t('input.total_bet_win_a_day_lotto')"
                        hide-details="auto"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="12" style="padding:0px;">
                    <v-checkbox v-model="dataConfig.enable_yuki" label="ដាក់ដំណើរការ(Yuki)"></v-checkbox>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="dataConfig.total_bet_win_a_day_yuki"
                        :rules="rules.total_bet_win_a_day_yuki"
                        :label="$t('input.total_bet_win_a_day_yuki')"
                        type="number"
                        hide-details="auto"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="12" style="padding:0px;">
                    <v-checkbox v-model="dataConfig.enable_pp78" label="ដាក់ដំណើរការ(Lottery)"></v-checkbox>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="dataConfig.total_bet_win_a_day_pp78"
                        :rules="rules.total_bet_win_a_day_pp78"
                        type="number"
                        :label="$t('input.total_bet_win_a_day_pp78')"
                        hide-details="auto"
                      />
                    </v-col>
                    <div class="col-12" style="text-align: end;">
                    <v-btn
                      color="primary"
                      :disabled="loading"
                      @click="validate"
                    >
                    {{ $t("button.save") }}
                    </v-btn>
                  </div>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    darkmode: {
      type: Boolean,
    },
    rules: {
      type: Object,
      default: function () {
        return {
          total_bet_win_a_day_lotto: [(v) => !!v || "Total Lotto is required", (v) =>
              (!!v && v > 0) ||
              "Total Lotto greater than 0"],
          total_bet_win_a_day_yuki: [(v) => !!v || "Total Yuki is required",
          (v) => (!!v && v > 0) ||
              "Total Yuki greater than 0"],
          total_bet_win_a_day_pp78: [(v) => !!v || "Total PP78 is required",
          (v) => (!!v && v > 0) ||
              "Total PP78 greater than 0"],
        };
      },
    },
    isValidate: {
      type: Boolean,
      default: true,
    },
    dataConfig: {
      type: Object,
      default: () => {
        return {
          total_bet_win_a_day_lotto: null,
          total_bet_win_a_day_yuki: null,
          total_bet_win_a_day_pp78: null,
          enable_lotto:true,
          enable_yuki:true,
          enable_pp78:true,
        };
      },
    },
  },
  data() {
    return {

    };
  },
  computed: {
    valid: {
      // getter
      get: function () {
        return this.isValidate;
      },
      // setter
      set: function (newValue) {
        this.$emit("update:isValidate", newValue);
      },
    },
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.$emit("submit");
      }
    },

    remove(item) {
      this.bankUser.permissions.splice(this.bankUser.permissions.indexOf(item), 1);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
