<template>
  <div v-if="dialog && user">
    <v-dialog
      :value="dialog"
      persistent
      transition="dialog-bottom-transition"
      max-width="50%"
    >
      <v-form ref="form" v-model="valid" lazy-validation>
        <div class="card">
          <div class="modal-header">
            <h5 class="modal-title" id="formModal">
              {{ $t("title.change_password_user") }} ({{ user.user_name }})
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="$emit('onCloseDialog')"
            >
              <span @click="$emit('onCloseDialog')" aria-hidden="true">×</span>
            </button>
          </div>

          <Loading :visible="loading" />
          <div class="card-body">
            <label>Password</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <i class="fas fa-key"></i>
                </div>
              </div>
              <input
                v-model="user.password"
                @input="$emit('getData3')"
                :type="showPassword ? 'text' : 'password'"
                :label="$t('input.password')"
                :rules="rules.password"
                @click:append="showPassword = !showPassword"
                class="form-control"
              />
            </div>
          </div>
          <div class="card-footer text-right">
            <button class="btn btn-primary" :disabled="loading" @click="validate">Save</button>
          </div>
        </div>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    darkmode: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    rules: {
      type: Object,
      default: function () {
        return {
          password: [
            (v) => !!v || "Password is required",
            (v) =>
              (!!v && v.length > 5) ||
              "Password must be greater than 5 characters",
          ],
        };
      },
    },
    isValidate: {
      type: Boolean,
      default: true,
    },
    user: {
      type: Object,
      default: function () {
        return {
          _id: null,
          user_name: null,
          password: null,
        };
      },
    },
  },
  computed: {
    valid: {
      // getter
      get: function () {
        return this.isValidate;
      },
      // setter
      set: function (newValue) {
        this.$emit("update:isValidate", newValue);
      },
    },
  },
  data: () => {
    return {
      showPassword: false,
    };
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.$emit("submit");
      }
    },
  },
};
</script>
