<template>
  <div v-if="dialog && users">
    <v-dialog :value="dialog" max-width="50%" persistent transition="dialog-bottom-transition">
      <v-card >
        <v-toolbar >
          <v-btn icon  @click="$emit('onCloseDialog')">
            <i class="fas fa-lg fa-window-close"></i>
          </v-btn>
          <v-toolbar-title class="text-uppercase">
            {{ $t('title.user_list') }} 5
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-text-field
              v-model="dataRequest.search"
              append-icon="mdi-magnify"
              :label="$t('input.search')"
              single-line
              clearable
              @input="$emit('getData5')"
          />
          <v-data-table
              style="font-family: 'khmer mef1'"
              class="elevation-1"
              item-key="_id"
              
              :headers="headers"
              :footer-props="footerProps"
              :items="users ? users.object : []"
              :server-items-length="users ? users.objectCount : null"
              :sort-desc.sync="dataRequest.descendingFormat"
              :sort-by.sync="dataRequest.sortByFormat"
              :page.sync="dataRequest.page"
              :items-per-page.sync="dataRequest.rowsPerPage"
              @update:page="$emit('getData5')"
              @update:items-per-page="$emit('getData5')"
              @update:sort-desc="$emit('getData5')"
              mobile-breakpoint="0"
          >
            <template v-slot:[`item.enable`]="{ item }">
              <v-chip label :color="item.enable ? 'green' : 'red'">
                <span class="text-white">
                  {{ item.enable ? "Active" : "Inactive" }}
                </span>
              </v-chip>
            </template>
             <template v-slot:[`item.balance`]="{ item }">
                  <span  v-if="item.role_id !=='612a3fcc1a1fcead8871e822' && (item.currency_type===1 || item.currency_type===3) " >
                  {{ currencyFormatUsd(item.balance)}}
                </span>
                <span   v-if="item.role_id !=='612a3fcc1a1fcead8871e822' && item.currency_type===2" >
                  {{ currencyFormatKh(item.balance)}}
                </span>
                <span   v-if="item.role_id !=='612a3fcc1a1fcead8871e822' && item.currency_type===4" >
                  {{ currencyFormatKh(item.balance)}}
                </span>
              
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon color="green"
                      small class="mr-2"
                      v-if="item.objChild > 0"
              >mdi-eye
              </v-icon>
              <v-icon color="green"
                      small class="mr-2"
                      disabled
                      v-else
              >mdi-eye-off
              </v-icon>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["darkmode", "dialog", "users", "dataRequest"],
  data: () => {
    return {
      dialogListUser: false,
      userChild: [],
      footerProps: {
        'items-per-page-options': [10, 20, 50, 100, 200]
      },
    }
  },
  computed: {
    headers() {
      return [
        {text: this.$t('header.user_name'), align: 'start', sortable: false, value: 'user_name'},
        { text: this.$t('header.bank_account'), value: 'account_info' },
        { text: this.$t('header.balance'), value: 'balance' },
        { text: this.$t('header.role'), value: 'role_name' },
        { text: this.$t('header.created_at'), value: 'updatedAt' },
        { text: this.$t('header.active'), value: 'enable' },
        { text: this.$t('header.actions'), value: 'actions', sortable: false }
      ];
    },
  },
  method:{
      currencyFormatKh(num) {
      num = parseFloat(num);
      // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      return parseInt(num).toLocaleString("en");
    },
    currencyFormatUsd(num) {
      num = parseFloat(num);
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      // return parseInt(num).toLocaleString("en");
    },
  }
}
</script>

<style scoped>

</style>
